<script>

	import { onMount, getContext } from 'svelte';
	import { fade, slide, fly } from "svelte/transition";

	import { v4 as uuid } from 'uuid';

	import AudienceView from "./AudienceView.svelte";

	import Timer from '../../ui/Timer.svelte';
	import Stopwatch from '../../ui/Stopwatch.svelte';
	import Button from "../../ui/Button.svelte";
	import Spinner from "../../ui/Spinner.svelte";
	import Switch from "../../ui/fields/Switch.svelte";
	import LiveIndicator from '../../ui/Player/LiveIndicator.svelte';
	import Dialog from "../../ui/Dialog.svelte";

	import {	displayInEventTz, epoch, session, event, syncClient, announcements } from '../../lib/stores.js';
	import { postServerData } from '../../lib/prelude.js';
	import {	showDate } from '../../lib/dt.js';

	const host = getContext('host');
	const broadcasting = getContext('broadcasting');
	const livestreamStatus = getContext('livestreamStatus');
	const broadcastStarted = getContext('broadcastStarted');
	const streamActivated = getContext('streamActivated');

	// It typically takes 31s or 32s to get from API launch to livestream active
	const launchWindow = 40;

	// Glass-to-glass latency is upwards of 5s
	const latencyAdjustment = 0;

	let sessionStarts;
	let sessionEnds;

	let shutdownTimeout;
	let mounted = false;
	// let stopped = false;
	let autostart = false;
	let autostartSwitch = false;

	let controlChannel;
	let control = {};

	let cutDialog = null;
	let autostartDialog = null;
	let sessionTooltip = false;

	function updateControl(obj) {
		if (host) {
			const json = JSON.stringify(obj);
			postServerData('virtual/control', {
				session: $session.ref,
				json: json
			});
		}
	}

	// function openCutDialog() {
	// 	cutDialog = {
	// 		text: "Are you sure you want to cut the stream for this session?",
	// 		actions: [
	// 			{
	// 				label: "Confirm",
	// 				red: true,
	// 				dispatch: "confirm"
	// 			},
	// 			{
	// 				label: "Cancel",
	// 				ghost: true,
	// 				red: true,
	// 				dispatch: "escape"
	// 			}
	// 		]
	// 	};
	// }

	// function cutVideo() {
	// 	cutDialog = null;
	// 	let obj = {
	// 		...control
	// 	};
	// 	obj.cut = true;
	// 	updateControl(obj);
	// }

	// function resumeVideo() {
	// 	launchWebInput();
	// 	let obj = {
	// 		...control
	// 	};
	// 	obj.cut = false;
	// 	updateControl(obj);
	// }

	function resetVideo() {
		$broadcasting = null;
		$broadcastStarted = false;
		$streamActivated = null;
		shutdownWebInput();
		let obj = {
			...control
		};
		obj.cut = false;
		updateControl(obj);
	}

	function confirmAutostart() {
		if (mounted && autostartSwitch) {
			autostartDialog = {
				text: "This will automatically start the stream as soon as the session begins.",
				info: [
					"Please confirm you want to proceed."
				],
				actions: [
					{
						label: "Confirm",
						dispatch: "confirm"
					},
					{
						label: "Cancel",
						ghost: true,
						dispatch: "escape"
					}
				]
			};
		} else {
			setAutostart();
		}
	}

	function setAutostart() {
		autostartDialog = null;
		autostart = autostartSwitch;
	}

	function resetAutostartSwitch() {
		autostartSwitch = autostart;
	}


	onMount(async () => {

		// TMP -- remove for production!!
		// resetVideo();

		// console.log('stream status', $livestreamStatus, $streamActivated, $broadcasting, control);

		sessionStarts = $session.epochStarts - latencyAdjustment;
		sessionEnds = $session.epochEnds - latencyAdjustment;

		if (host && ($epoch > ($session.epochStarts - launchWindow)) && ($epoch < sessionEnds)) {
			launchWebInput();
		}

		try {

			controlChannel = await $syncClient.document($session.controlChannel);
			control = controlChannel.data;

			controlChannel.on('updated', (args) => {
				control = args.data;
			});

		} catch (e) {
			console.log("Sync error", e, $session.controlChannel);
		}

		mounted = true;

	});

	function autoLaunch() {
		if (host) {
			if ((sessionStarts - $epoch) == launchWindow) {
				launchWebInput();
			} else if (!$broadcasting && ($epoch == (sessionStarts + 10))) {
				sessionTooltip = true;
			}
			if (autostartDialog && ($epoch >= (sessionStarts - 7))) {
				autostartDialog = null;
			}
		}
	}

	function launchWebInput(name) {
		if (host) {
			// console.log('Launching web input', name);
			$streamActivated = null;
			let obj = {
				...control
			};
			obj.cut = false;
			updateControl(obj);
			postServerData('virtual/studio/webinput/launch', {
				session: $session.ref,
				name: name ? name : $session.name
			});
		}
	}

	function shutdownWebInput() {
		if (host) {
			// console.log('Shutdown web input');
			$streamActivated = null;
			postServerData('virtual/studio/webinput/shutdown', {
				session: $session.ref
			});
		}
	}

	function startRecording() {
		const recordingName = 'Studio recording of ' + $session.name + ' (' + showDate() + ')';
		launchWebInput(recordingName);
		$broadcasting = $epoch;
	}

	function startBroadcast() {
		// console.log('startBroadcast', $livestreamStatus);
		$broadcasting = $epoch;
		$broadcastStarted = true;
		if (($livestreamStatus == 'idle') || ($livestreamStatus === null)) {
			launchWebInput();
		}
	}

	function stopBroadcast() {
		$broadcasting = null;
		if (control.cut || (!$broadcastStarted) || ($epoch > sessionEnds) || ((($livestreamStatus == 'idle') || ($livestreamStatus === null)) && $streamActivated && host)) {
			$streamActivated = null;
			shutdownWebInput();
		}
	}

	function setStreamActivated() {
		// 03 Feb 2025 -- why did I need to know that the livestreamStatus is **active** (as opposed to not-null) ..?

		// if ($livestreamStatus == 'active') {
		// 	$streamActivated = $epoch;
		// } else if ($livestreamStatus == 'idle') {
		// 	$streamActivated = null;
		// }

		if ($livestreamStatus == 'idle') {
			$streamActivated = null;
		} else {
			if (!$streamActivated) {
				$streamActivated = $epoch;
			}
		}
	}

	$: autoLaunch($epoch);

	$: if (autostart && ($epoch == ($session.epochStarts - latencyAdjustment))) {
		startBroadcast();
	}

	$: if (control.cut) {
		stopBroadcast();
	}

	$: confirmAutostart(autostartSwitch);

	$: setStreamActivated($livestreamStatus);

	// $: console.log('LiveStream status', $broadcasting, $livestreamStatus, $streamActivated, control);
	// $: console.log('streamActivated', $streamActivated);

</script>

<style>
	.wrap {
		flex: 0 0 auto;
		/*border-top: 2px solid var(--shadow);
		border-bottom: 2px solid var(--shadow);*/
		position: relative;
		height: 0;
		transition: height 0.6s ease;
		overflow: hidden;
		background: var(--panelColor);
		height: 3.8rem;
		border-radius: 4px;
	}

	.wrap > div {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		transform: translateY(-50%);
		padding: 0.75rem;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		gap: 1rem;
	}

	/*.wrap.started {
		height: 5.2rem;
	}*/
	/*.wrap.cut > div {
		text-align: center;
		display: block;
	}*/

	.wrap > div > p.max {
		flex: 1 0 max-content;
		text-align: right;
	}

	.timer {
		font-size: 0.6875rem;
		line-height: 1.25;
	}
	.wrap .timer :global(span.timer) {
		font-size: 1.2rem;
	}
	.imminent.timer :global(span.timer) {
		color: var(--red);
		font-weight: 600;
	}

	@keyframes flash {
		0% { opacity: 1 }
		50% { opacity: 0 }
		100% { opacity: 1 }
	}

	.flash :global(span.timer) {
		animation-name: flash;
		animation-duration: 1.6s;
		animation-iteration-count: infinite;
	}

	@media (min-width: 1101px) and (max-width: 1160px) {
		.wrap.host {
			height: 6rem;
		}
		.wrap > div {
			flex-wrap: wrap;
		}
	}

	.switch {
		font-size: 0.6875rem;
		display: flex;
		gap: 0.5rem;
		align-items: center;
		padding-bottom: 0.1rem;
	}
	.switch label {
		font-weight: 400;
	}
	.switch :global(.toggle-group) {
		top: auto;
	}

	.overlay {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 12003;
	}
	.overlay:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		opacity: 0.7;
	}
	.overlay > div {
		width: clamp(30vw, 28rem, 80vw);
		box-sizing: border-box;
		background: var(--panelColor);
		color: var(--textColor);
		box-shadow: 0 20px 80px var(--shadow);
		position: relative;
		border-radius: 4px;
		position: relative;
		padding: 2rem 2rem 2rem 8rem;
	}
	.overlay p {
		margin: 0;
	}
	.overlay > div p + p {
		margin-top: 0.8rem;
	}
	.overlay .countdown {
		position: absolute;
		top: 1.8rem;
		left: 2rem;
		font-family: system-ui;
		font-variant-numeric: tabular-nums;
		font-size: 3rem;
		color: var(--accentColor);
		line-height: 1;
	}
	.overlay :global(.azn_spinner) {
		position: absolute;
		top: 1.4rem;
		left: 1.6rem;
	}
	.wrap :global(.liveindicator) {
		top: 0.75rem;
		left: auto;
		right: 0.75rem;
		font-size: 12px;
	}

	.wrap :global(button) {
		min-width: 8rem;
	}

	.tooltip {
		position: absolute;
		bottom: 0.1rem;
		right: -0.4rem;
		width: 6rem;
		transform: translate(100%, 0%);
		border-radius: 4px;
		background: var(--accentColor);
		color: var(--panelColor);
		padding: 0.8rem 1.4rem 0.8rem 0.8rem;
		line-height: 1.4;
		font-size: 0.6875rem;
		font-weight: 600;
		box-shadow: 0 3px 10px var(--shadow);
		z-index: 1000;
	}
	.tooltip:before {
		content: "";
		position: absolute;
		top: 50%;
		left: -0.36rem;
		width: 0.4rem;
		height: 0.4rem;
		transform: rotate(45deg) translateY(-50%);
		background: var(--accentColor);
		box-shadow: 0 3px 10px var(--shadow);
		z-index: -1;
	}
	.tooltip:after {
		content: "";
		position: absolute;
		inset: 0;
		background: var(--accentColor);
		z-index: -1;
		border-radius: 4px;
	}

	.tooltip button {
		position: absolute;
		top: 0;
		right: 0;
		color: var(--panelColor);
		background: transparent;
		border: 0;
		padding: 0;
		width: 1.4rem;
		height: 1.4rem;
		transition: transform 0.1s ease;
		cursor: pointer;
	}

	.tooltip button:hover,
	.tooltip button:active,
	.tooltip button:focus {
		transform: scale(1.2);
	}

	@media (max-width: 1100px) {
		.tooltip {
			right: 0.4rem;
			transform: translate(0,0);
			bottom: 4.1rem;
		}
		.tooltip:before {
			top: auto;
			bottom: -0.3rem;
			left: 50%;
			transform: rotate(45deg) translateX(-50%);
		}
	}

	.control {
		overflow: visible;
	}

</style>

{#if mounted && $session && (host || !control.cut) && ($broadcasting || ($epoch < sessionEnds))}
	<div class="control" transition:slide|local={{ duration: 300 }}>
		<AudienceView/>
		<div class="wrap" class:host>
			{#if $broadcasting || $broadcastStarted}
				<div>
					{#if $epoch >= sessionStarts}
						<p class="timer" in:fade={{ duration: 600, delay: 800 }} class:imminent={$epoch > ($session.epochEnds - 60)}>
							<span>Session ends in:</span>
							<br/><Timer duration={($session.epochEnds - latencyAdjustment) - $epoch} />
						</p>
					{/if}
					{#if host}
						{#if $broadcastStarted && !$broadcasting}
							<p in:fade={{ duration: 400 }}>
								<Button red={true} on:click={startBroadcast} label="Resume stream">
									<svg viewBox="0 0 80 80"><path d="M22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM27.98 54.02c-.51 0-1.02-.2-1.41-.59-7.41-7.41-7.41-19.46 0-26.87.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-5.85 5.85-5.85 15.36 0 21.21.78.78.78 2.05 0 2.83-.4.4-.91.59-1.42.59zM52.02 54.02c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 2.83-2.83 4.39-6.59 4.39-10.6s-1.56-7.77-4.39-10.61c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 3.58 3.59 5.56 8.36 5.56 13.44s-1.98 9.85-5.57 13.43c-.39.4-.9.59-1.41.59z"/><circle cx="40" cy="40" r="9"/></svg>
								</Button>
							</p>
						{:else if $epoch >= sessionStarts}
							{#if $streamActivated}
								<p in:fade={{ duration: 400 }}>
									<Button red={true} on:click={stopBroadcast} label="Stop stream">
										<svg viewBox="0 0 80 80"><path d="M60 62h-40c-1.1 0-2-.9-2-2v-40c0-1.1.9-2 2-2h40c1.1 0 2 .9 2 2v40c0 1.1-.9 2-2 2z"/></svg>
									</Button>
								</p>
							{/if}
						{:else}
							{#if $streamActivated}
								<p>
									<span class="timer" in:fade={{ duration: 600 }}>
										<Stopwatch started={$streamActivated} />
									</span>
								</p>
								<p>
									<Button red={true} on:click={stopBroadcast} label="Stop recording">
										<svg viewBox="0 0 80 80"><path d="M60 62h-40c-1.1 0-2-.9-2-2v-40c0-1.1.9-2 2-2h40c1.1 0 2 .9 2 2v40c0 1.1-.9 2-2 2z"/></svg>
									</Button>
								</p>
							{/if}
						{/if}
					{:else}
						{#if $streamActivated}
							{#if $epoch >= sessionStarts}
								<LiveIndicator/>
							{:else}
								<span class="timer" in:fade={{ duration: 600 }}>
									<Stopwatch started={$streamActivated} />
								</span>
							{/if}
						{/if}
					{/if}
				</div>
			{:else if (host && ($epoch < (sessionStarts - 3600 - ($session.duration * 60))))}
				<div>
					<p>
					</p>
					<p>
						<Button red={true} ghost={false} on:click={startRecording} label="Start recording">
							<svg viewBox="0 0 80 80"><circle cx="40" cy="40" r="23"/></svg>
						</Button>
					</p>
				</div>
			{:else}
				{#if host}
					{#if control.cut}
						<div in:fade={{ duration: 400 }}>
							<p>
								<Button red={true} on:click={startBroadcast} label="Resume stream">
									<svg viewBox="0 0 80 80"><path d="M22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM27.98 54.02c-.51 0-1.02-.2-1.41-.59-7.41-7.41-7.41-19.46 0-26.87.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-5.85 5.85-5.85 15.36 0 21.21.78.78.78 2.05 0 2.83-.4.4-.91.59-1.42.59zM52.02 54.02c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 2.83-2.83 4.39-6.59 4.39-10.6s-1.56-7.77-4.39-10.61c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 3.58 3.59 5.56 8.36 5.56 13.44s-1.98 9.85-5.57 13.43c-.39.4-.9.59-1.41.59z"/><circle cx="40" cy="40" r="9"/></svg>
								</Button>
							</p>
						</div>
					{:else}
						<div>
							{#if $epoch >= sessionStarts}
								<p class="timer imminent flash">
									<span>Session started!</span>
									<br/><span class="timer">00:00</span>
								</p>
								<p class="max">
									<Button red={true} shout={false} on:click={startBroadcast} label="Start stream">
										<svg viewBox="0 0 80 80"><path d="M22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM27.98 54.02c-.51 0-1.02-.2-1.41-.59-7.41-7.41-7.41-19.46 0-26.87.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-5.85 5.85-5.85 15.36 0 21.21.78.78.78 2.05 0 2.83-.4.4-.91.59-1.42.59zM52.02 54.02c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 2.83-2.83 4.39-6.59 4.39-10.6s-1.56-7.77-4.39-10.61c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 3.58 3.59 5.56 8.36 5.56 13.44s-1.98 9.85-5.57 13.43c-.39.4-.9.59-1.41.59z"/><circle cx="40" cy="40" r="9"/></svg>
									</Button>
								</p>
							{:else}
								<p class="timer" class:imminent={$epoch > (sessionStarts - 60)}>
									<span>Session starts in:</span>
									<br/><Timer duration={sessionStarts - $epoch} />
								</p>
								{#if $epoch < (sessionStarts - 7)}
									<p class="switch" out:fade|local={{ duration: 200 }}>
										<Switch ref="autostart" bind:value={autostartSwitch}/>
										<label for="autostart">Auto-stream</label>
									</p>
								{/if}
								{#if $epoch > (sessionStarts - 7)}
									<p in:fade|local={{ duration: 200 }}>
										<Button red={true} shout={false} on:click={startBroadcast} label="Start stream">
											<svg viewBox="0 0 80 80"><path d="M22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM22.32 59.68c-.51 0-1.02-.2-1.41-.59-5.1-5.1-7.91-11.88-7.91-19.09s2.81-13.99 7.91-19.09c.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-4.35 4.34-6.74 10.12-6.74 16.26s2.39 11.92 6.74 16.26c.78.78.78 2.05 0 2.83-.39.39-.91.59-1.42.59zM57.68 59.68c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 8.97-8.97 8.97-23.56 0-32.53-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 10.53 10.53 10.53 27.66 0 38.18-.4.4-.91.6-1.42.6zM27.98 54.02c-.51 0-1.02-.2-1.41-.59-7.41-7.41-7.41-19.46 0-26.87.78-.78 2.05-.78 2.83 0 .78.78.78 2.05 0 2.83-5.85 5.85-5.85 15.36 0 21.21.78.78.78 2.05 0 2.83-.4.4-.91.59-1.42.59zM52.02 54.02c-.51 0-1.02-.2-1.41-.59-.78-.78-.78-2.05 0-2.83 2.83-2.83 4.39-6.59 4.39-10.6s-1.56-7.77-4.39-10.61c-.78-.78-.78-2.05 0-2.83.78-.78 2.05-.78 2.83 0 3.58 3.59 5.56 8.36 5.56 13.44s-1.98 9.85-5.57 13.43c-.39.4-.9.59-1.41.59z"/><circle cx="40" cy="40" r="9"/></svg>
										</Button>
									</p>
								{/if}
							{/if}
						</div>
					{/if}
				{:else}
					<div>
						<p class="timer" class:imminent={$epoch > (sessionStarts - 60)} class:flash={$epoch > sessionStarts}>
							<span>Session starts in:</span>
							<br/><Timer duration={sessionStarts - $epoch} />
						</p>
					</div>
				{/if}
			{/if}
		</div>
	</div>
{/if}

{#if sessionTooltip}
	<span class="tooltip" transition:fly|local={{ duration: 400, x: -5 }}>
		<button type="button" class="close" on:click={() => { sessionTooltip = false }}>
			<svg viewBox="0 0 80 80"><title>Dismiss</title><path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/></svg>
		</button>
		<span>Don’t forget to start the stream!</span>
	</span>
{/if}

{#if $broadcasting && (($livestreamStatus == 'idle') || ($livestreamStatus === null))}
	{#if $streamActivated}
		{#if host}
			<Dialog
				text="The stream has been unexpectedly disconnected."
				actions={[
					{
						label: "Reconnect stream",
						dispatch: "confirm"
					},
					{
						label: "End stream",
						ghost: true,
						dispatch: "escape"
					}
				]}
				on:confirm={startBroadcast}
				on:escape={stopBroadcast}
			/>
		{/if}
	{:else}
		<div class="overlay" in:fade|local={{ duration: 400}} out:fade|local={{ duration: 200}}>
			<div>
				{#if $epoch < ($broadcasting + 10)}
					<div class="countdown">{String(($broadcasting + 10) - $epoch).padStart(2, '0')}</div>
					<p><strong>Please wait a few seconds for the stream to connect.</strong></p>
				{:else}
					<Spinner size="80" speed="1200" color={$event.theme.colors[$event.theme.virtual['accentColor']]} thickness="1.3" gap="30" />
					<p><strong>Hold tight – the stream should be ready in just a moment.</strong></p>
				{/if}
				<p>When this message disappears, it’s safe to begin your presentation.</p>
			</div>
		</div>
	{/if}
{/if}

<!-- {#if cutDialog}
	<Dialog
		{...cutDialog}
		on:escape={() => { cutDialog = null }}
		on:confirm={cutVideo}
	/>
{/if} -->

{#if autostartDialog}
	<Dialog
		{...autostartDialog}
		on:escape={resetAutostartSwitch}
		on:confirm={setAutostart}
	/>
{/if}