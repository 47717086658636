export const randomIntFromInterval = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
};

export const arrayToEnglishList = (array, conjunction) => {
  return array
    .join(", ")
    .replace(/, ((?:.(?!, ))+)$/, ` ${conjunction} $1`);
};

export const sleep = (ms) => {
  return new Promise(res => setTimeout(res, ms));
};