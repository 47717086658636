<script>

	// 29 Jan 2025
	// Extensive rewrite:
	// -- focus on LiveKit exclusively (remove Twilio Video)
	// -- set store of TrackPublications from Room event handlers
	// -- remove cleanup altogether

	import { onMount, onDestroy, setContext, tick, createEventDispatcher } from 'svelte';
	import { writable } from 'svelte/store';

	import { Room, RoomEvent, VideoPresets, AudioPresets, DisconnectReason, createLocalAudioTrack, setLogExtension } from 'livekit-client';

	import { v4 as uuid } from 'uuid';

	import * as Sentry from "@sentry/svelte";

	import LeftPanel from './LeftPanel.svelte';
	import CenterPanel from './CenterPanel.svelte';
	import RightPanel from './RightPanel.svelte';
	import Settings from './Settings.svelte';

	import Overlay from "../ui/Overlay.svelte";
	import Dialog from "../ui/Dialog.svelte";
	import InactivityTimeout from "../ui/InactivityTimeout.svelte";

	import { event, session, syncClient, syncChannels, attendee, studioOptions, busy, epoch, currentSessions, backstage, bigError } from "../lib/stores.js";
	import { setTrackOptions } from '../lib/localTracks.js';
	import { getServerData, postServerData } from '../lib/prelude.js';

	const dispatch = createEventDispatcher();

	let sessionTimeoutId;

	let participants = writable([]);
	setContext('participants', participants);

	let layout = writable('gap');
	setContext('layout', layout);

	let banners = writable([]);
	setContext('banners', banners);

	let backgrounds = writable([]);
	setContext('backgrounds', backgrounds);

	let overlays = writable([]);
	setContext('overlays', overlays);

	let clips = writable([]);
	setContext('clips', clips);

	let clipTokens = writable({});
	setContext('clipTokens', clipTokens);

	let banner = writable(null);
	setContext('banner', banner);

	let background = writable(null);
	setContext('background', background);

	let overlay = writable(null);
	setContext('overlay', overlay);

	let clip = writable(null);
	setContext('clip', clip);

	let highlight = writable(null);
	setContext('highlight', highlight);

	let broadcasting = writable(false);
	setContext('broadcasting', broadcasting);

	let broadcastStarted = writable(false);
	setContext('broadcastStarted', broadcastStarted);

	let streamActivated = writable(null);
	setContext('streamActivated', streamActivated);

	let livestreamStatus = writable('idle');
	setContext('livestreamStatus', livestreamStatus);

	let flipDuration = writable(0);
	setContext('flipDuration', flipDuration);

	let participantCount = writable(0);
	setContext('participantCount', participantCount);

	let room = writable(null);
	setContext('room', room);

	let trackPublications = writable({});
	setContext('trackPublications', trackPublications);

	let localVideoTrack = writable(null);
	setContext('localVideoTrack', localVideoTrack);

	let localAudioTrack = writable(null);
	setContext('localAudioTrack', localAudioTrack);

	let host = (($attendee.type == 'login') || ($backstage.includes($session.ref))) ? true : false;
	setContext('host', host);

	let audienceViewMuted = writable(true);
	setContext('audienceViewMuted', audienceViewMuted);

	let renderKey = writable(null);
	setContext('renderKey', renderKey);

	let mounted = false;

	let syncChannel;
	let studioChannel;
	let updateChannelFromStores = true;
	let attendeeNotifications;
	let eventNotifications;

	let channelDebouncer;

	let roomName;
	let roomToken;
	let providerLoaded = false;
	let processorsLoaded = false;

	let livekitHost;
	let livekitRoom;

	let videoDevice;
	let audioDevice;

	let nestedOverlay = false;

	onMount(async () => {

		$renderKey = $epoch;

		const studioData = await getServerData('virtual/studio/provider', {
			session: $session.ref
		});

		if (studioData) {
			roomToken = studioData.roomToken;
			livekitHost = studioData.livekitHost;
			providerLoaded = true;
			processorsLoaded = true
		}

		try {
			attendeeNotifications = await $syncClient.list($syncChannels.attendee);
			attendeeNotifications.on('itemAdded', attendeeNotificationAdded);
		} catch (e) {
			console.log("Sync error", e, $syncChannels.attendee);
		}

		try {
			eventNotifications = await $syncClient.list($syncChannels.event);
			eventNotifications.on('itemAdded', eventNotificationAdded);
		} catch (e) {
			console.log("Sync error", e, $syncChannels.event);
		}

	});

	onDestroy(() => {
		if (attendeeNotifications) attendeeNotifications.close();
		if (eventNotifications) eventNotifications.close();
		$localVideoTrack = null;
		$localAudioTrack = null;
		closeRoom();
	});

	function attendeeNotificationAdded(message) {
		if (message && message.item && message.item.data && message.item.data.type) {
			if (message.item.data.sessionRef && (message.item.data.sessionRef == $session.ref)) {
				if (message.item.data.type == 'poll') {
					$session.pollResponses[message.item.data.poll] = message.item.data.response;
					$session = $session;
				}
			}
		}
	}
	
	function eventNotificationAdded(message) {
		const messageData = message.item.data;
		if (messageData && messageData.type && (messageData.type == 'livestream')) {
			if (messageData.stream && $session && (messageData.stream == $session.livestream) && messageData.status) {
				// console.log('livestreamStatus', messageData.status);
				$livestreamStatus = messageData.status;
				if ((!$livestreamStatus || $livestreamStatus == 'idle')) {
					$broadcastStarted = false;
				}
			}
		}
	}

	async function setLocalTracks() {
		if (!livekitRoom) {
			livekitRoom = createLivekitRoom();
			livekitRoom.prepareConnection('wss://' + livekitHost, roomToken);
			// console.log('prepareConnection');
		}
	}

	function createLivekitRoom() {

		setLogExtension((level, msg, context) => {
			if (level == 4) {
				const j = JSON.stringify(context);
				postServerData('virtual/studio/debug', {
					sessionRef: $session.ref,
					message: msg,
					json: j
				});
			}
		});

		return new Room({
			adaptiveStream: true,
			dynacast: true,
			disconnectOnPageLeave: true,
			stopLocalTrackOnUnpublish: true,
			publishDefaults: {
				simulcast: true,
				videoSimulcastLayers: [
					VideoPresets.h540,
					VideoPresets.h1080
				]
			},
			videoCaptureDefaults: {
    			resolution: VideoPresets.h1080.resolution,
  			}
		});

	}

	async function initialiseSession() {

		$busy = true;

		if (!roomName) {

			const sessionData = await getServerData('virtual/session', {
				session: $session.ref,
				studio: 1
			});

			if (sessionData) {

				$session.syncChannel = sessionData.syncChannel;
				$session.controlChannel = sessionData.controlChannel;
				$session.livestream = sessionData.livestream;
				$session.playback = sessionData.playback;
				$session.token = sessionData.token;

				if (sessionData.privateChannel) $session.privateChannel = sessionData.privateChannel;

				if (sessionData.studioChannel) {

					studioChannel = await $syncClient.document(sessionData.studioChannel);
					setStoresFromSync(studioChannel.data);

					// $broadcasting = null;

					studioChannel.on('updated', (args) => {
						setStoresFromSync(args.data);
					});

				}

				if ($session.syncChannel) {
					syncChannel = await $syncClient.list($session.syncChannel);
					syncChannel.on('itemAdded', function(i) {
						if (i.item.data.type == 'studio') {
							refreshLibrary(i.item.data.lib);
						}
					});
				}

				if (sessionData.room) {
					roomName = sessionData.room;
				}

				if (!roomToken && sessionData.roomToken) {
					roomToken = sessionData.roomToken;
				}

				if (!livekitHost && sessionData.livekitHost) {
					livekitHost = sessionData.livekitHost;
				}

				$backgrounds = sessionData.backgrounds;
				$banners = sessionData.banners;
				$overlays = sessionData.overlays;
				$clips = sessionData.clips;

				$livestreamStatus = sessionData.livestreamStatus;

				// console.log('session init: livestreamStatus', $livestreamStatus);

				if ((!$livestreamStatus || $livestreamStatus == 'idle')) {
					$broadcastStarted = false;
				}

				getClipTokens(true);

				$session.pollResponses = sessionData.pollResponses ? sessionData.pollResponses : {};

			} else {

				Sentry.captureMessage("initialiseSession: couldn’t obtain sessionData", "error");

			}

		}

		if (!$room) {

			if (!livekitRoom) {
				livekitRoom = createLivekitRoom();
			}

			// console.log('livekit room created');

			$room = livekitRoom;

			try {

				await $room.connect('wss://' + livekitHost, roomToken, {
					autoSubscribe: true
				});

			} catch (err) {

				Sentry.captureException(err);

				const j = JSON.stringify({
					err: err
				});

				postServerData('virtual/studio/debug', {
					sessionRef: $session.ref,
					message: 'initialiseSession: couldn’t create LiveKit room',
					json: j
				});

				$bigError = 'livekit';

			}

			if ($room) {

				$room.on(RoomEvent.TrackSubscribed, (e) => {
					// console.log('RoomEvent.TrackSubscribed', e);
					// cleanupParticipants('RoomEvent.TrackSubscribed');
					setTrackPublications();
				});

				$room.on(RoomEvent.TrackSubscriptionFailed, (sid, participant, err) => {
					let pub = sid;
					participant.trackPublications.forEach((publication) => {
						if (publication.trackSid == sid) {
							pub = publication;
						}
					});
					const j = JSON.stringify({
						sid: sid,
						err: err,
						pub: pub
					});
					postServerData('virtual/studio/debug', {
						sessionRef: $session.ref,
						message: 'TrackSubscriptionFailed',
						json: j
					});
				});

				// $room.on(RoomEvent.ConnectionStateChanged, (e) => {
				// 	// console.log('RoomEvent.ConnectionStateChanged', e);
				// 	if ($room && $session) {
				// 		const j = JSON.stringify(e);
				// 		postServerData('virtual/studio/debug', {
				// 			sessionRef: $session.ref,
				// 			message: 'ConnectionStateChanged',
				// 			json: j
				// 		});
				// 	}
				// });

				$room.on(RoomEvent.Disconnected, (r) => {
					// cf. https://docs.livekit.io/client-sdk-js/enums/DisconnectReason.html
					const reason = DisconnectReason[r];
					// console.log('RoomEvent.Disconnected', reason);
					if (reason != 'CLIENT_INITIATED') {
						exitSession();
					}
				});

				$room.on(RoomEvent.Reconnecting, (e) => {
					console.log('RoomEvent.Reconnecting', e);
					// If you close your laptop, no events fire -- not even Disconnect
					// but when you re-open, you'll reconnect
					// We actually want you to go through the greenroom again though
					exitSession();
				});

				// $room.on(RoomEvent.TrackPublished, (p) => {
				// 	if (p && !(p.trackName && p.trackName == ('dummy'))) {
				// 		// console.log('Subscribing to track...', p);
				// 		p.setSubscribed(true);
				// 	}
				// });

				// // Also subscribe to tracks published before participant joined
				// $room.remoteParticipants.forEach((participant) => {
				// 	participant.trackPublications.forEach((publication) => {
				// 		if (!(publication.trackName && publication.trackName == ('dummy'))) {
				// 			// console.log('Subscribing to track...', publication);
				// 			publication.setSubscribed(true);
				// 		}
				// 	});
				// });

				$room.on(RoomEvent.TrackUnpublished, (e) => {
					// console.log('RoomEvent.TrackUnpublished', $streamActivated);
					// // cleanupParticipants('RoomEvent.TrackUnpublished');
					// if ((e.kind == 'audio') && $streamActivated) {
					// 	setTimeout(() => {
					// 		createDummyAudioTrack();
					// 	}, 1000);
					// }
					setTrackPublications();
				});

				$room.on(RoomEvent.LocalTrackPublished, (e) => {
					// console.log('RoomEvent.LocalTrackPublished', e);
					// cleanupParticipants('RoomEvent.LocalTrackPublished');
					if (e && !(e.trackName && (e.trackName == 'dummy'))) {
						addToParticipants(e);
						setTrackPublications();
					}
				});

				$room.on(RoomEvent.LocalTrackUnpublished, (e) => {
					// console.log('RoomEvent.LocalTrackUnpublished', e);
					if (e && !(e.trackName && (e.trackName == 'dummy'))) {
						if (e.source && e.source.startsWith('screen_share')) {
							let filtered = $participants.filter(p => p.id != 's_' + $attendee.ref);
							$participants = filtered;
						}
						setTrackPublications();
					}
				});

				$room.on(RoomEvent.TrackMuted, (e) => {
					// console.log('RoomEvent.TrackMuted', e);
					setTrackPublications();
				});

				$room.on(RoomEvent.TrackUnmuted, (e) => {
					// console.log('RoomEvent.TrackMuted', e);
					setTrackPublications();
				});

				// $room.on(RoomEvent.TrackStreamStateChanged, (p,s) => {
				// 	const j = JSON.stringify({
				// 		RemoteTrackPublication: p,
				// 		streamState: s
				// 	});
				// 	postServerData('virtual/studio/debug', {
				// 		sessionRef: $session.ref,
				// 		message: 'TrackStreamStateChanged',
				// 		json: j
				// 	});
				// });

				// $room.on(RoomEvent.participantDisconnected, (p) => {
				// 	const j = JSON.stringify(p);
				// 	postServerData('virtual/studio/debug', {
				// 		sessionRef: $session.ref,
				// 		message: 'participantDisconnected',
				// 		json: j
				// 	});
				// });

				if ($localVideoTrack) {
					let localVideoTrackPublication = await $room.localParticipant.publishTrack($localVideoTrack);
					if (!localVideoTrackPublication) {
						Sentry.captureMessage("initialiseSession: couldn’t publish local video track", "error");
					}
				}

				if ($localAudioTrack) {
					let localAudioTrackPublication = await $room.localParticipant.publishTrack($localAudioTrack, {
						audioPreset: AudioPresets.speech
					});
					if (!localAudioTrackPublication) {
						Sentry.captureMessage("initialiseSession: couldn’t publish local audio track", "error");
					}
				}

			} else {

				Sentry.captureMessage("initialiseSession: null room", "error");

			}

		}

		sessionTimeoutId = uuid();

		// console.log('session mounted...');
		mounted = true;

		$busy = false;

	}

	// async function createDummyAudioTrack() {		
	// 	// 04 Feb 2025 -- a massive kludge!
	// 	// When a remote audio track is unpublished, the topmost participant still in the room attempts to publish a dummy to replace it.
	// 	// Real participants all ignore dummy published tracks.
	// 	// But the Mux web input subscribes and fails.
	// 	// However, this keeps it from reusing the transceiver previously allocated to the unpublished track.
	// 	// So when a participant rejoins, the web input should successfully subscribe to their audio.

	// 	console.log('createDummyAudioTrack');

	// 	let isTopParticipant = false;

	// 	if (($participants[0].id == $attendee.ref) || ($participants[0].id.substring(2) == $attendee.ref)) {
	// 		console.log('I AM THE TOP PARTICIPANT', $attendee.ref);
	// 		isTopParticipant = true;
	// 	}

	// 	if ($room && isTopParticipant) {

	// 		let dummyAudioTrack = await createLocalAudioTrack();

	// 		// const tj = JSON.stringify(dummyAudioTrack);
	// 		// postServerData('virtual/studio/debug', {
	// 		// 	sessionRef: $session.ref,
	// 		// 	message: 'dummyAudioTrack',
	// 		// 	json: tj
	// 		// });

	// 		const streamName = 'dummy' + $epoch.toString().substr(-5);
	// 		let dummyAudioTrackPublication = await $room.localParticipant.publishTrack(dummyAudioTrack, {
	// 			name: 'dummy',
	// 			stream: streamName
	// 		});

	// 		const pj = JSON.stringify(dummyAudioTrackPublication);
	// 		postServerData('virtual/studio/debug', {
	// 			sessionRef: $session.ref,
	// 			message: 'dummyAudioTrackPublication',
	// 			json: pj
	// 		});

	// 	}

	// }

	let trackPublicationsDebouncer;

	function setTrackPublications() {
		clearTimeout(trackPublicationsDebouncer);
		trackPublicationsDebouncer = setTimeout(() => {
			if ($room) {
				let t = {};
				$room.localParticipant.trackPublications.forEach((publication) => {

					// // 04 Feb 2025 -- disregard dummy tracks
					// if (publication.trackName && (publication.trackName == 'dummy')) {
					// 	return;
					// }

					let participantId = $attendee.ref;

					let i = participantId;

					if ((publication.source == 'screen_share') || (publication.source == 'screen_share_audio')) {
						i = 's_' + participantId;
					}

					const k = publication.kind;

					if (!t[i]) {
						t[i] = {};
					}

					t[i][k] = publication;

				});
				$room.remoteParticipants.forEach((participant) => {
					participant.trackPublications.forEach((publication) => {

						// // 04 Feb 2025 -- disregard dummy tracks
						// if (publication.trackName && (publication.trackName == 'dummy')) {
						// 	return;
						// }

						if (publication.isSubscribed) {

							let i = participant.identity;

							if ((publication.source == 'screen_share') || (publication.source == 'screen_share_audio')) {
								i = 's_' + participant.identity;
							}

							// let i = participant.metadata;

							// if ((publication.source == 'screen_share') || (publication.source == 'screen_share_audio')) {
							// 	i = 's_' + participant.metadata;
							// }

							const k = publication.kind;

							if (!t[i]) {
								t[i] = {};
							}

							t[i][k] = publication;

						}

					});
				});
				$trackPublications = t;
				// console.log('trackPublications', $trackPublications);
			}
		}, 400);
	}

	async function addToParticipants(localTrackPublication) {

		// If I'm not already in the participant list, add me.
		
		await tick();

		let participantId = $attendee.ref;
		let screenShare = false;

		if (localTrackPublication) {

			// // 04 Feb 2025 -- disregard dummy tracks
			// if (localTrackPublication.trackName && (localTrackPublication.trackName == 'dummy')) {
			// 	return;
			// }

			if ((localTrackPublication.source == 'screen_share') || (localTrackPublication.source == 'screen_share_audio')) {
				participantId = 's_' + $attendee.ref;
				screenShare = true;
			}

		}

		// console.log('addToParticipants', participantId, screenShare);

		let found = false;

		for (const p of $participants) {
			if (p.id == participantId) {
				found = true;
				break;
			}
		}

		if (found) {

			setParticipantOptions();
			// setParticipantSid(track);

		} else {

			// console.log('Adding to participants...', localTrackPublication);

			$participants.push({
				id: participantId,
				// t: sid,
				o: false,
				s: false,
				m: false,
				f: screenShare ? false : $studioOptions.mirrorCamera,
				b: screenShare ? false : $studioOptions.blurBackground
			});

			$participants = $participants;

			// console.log('adding attendee to participants...', participantId, $participants);

		}

	}

	async function closeRoom() {
		console.log('closing room...');
		// if ($localVideoTrack) await $localVideoTrack.stop();
		// if ($localAudioTrack) await $localAudioTrack.stop();
		if ($room) {
			$room.disconnect();
			$room = null;
		}
		console.log('closeRoom complete');
	}

	async function restoreSession() {
		await setLocalTracks();
		mounted = false;
	}

	let libraryDebouncers = {};

	function refreshLibrary(kind) {
		clearTimeout(libraryDebouncers[kind]);
		libraryDebouncers[kind] = setTimeout(async () => {
			if ($session) {
				const newData = await getServerData('virtual/studio/' + kind, {
					sessionRef: $session.ref
				});
				if (kind == 'backgrounds') {
					$backgrounds = newData;
				} else if (kind == 'overlays') {
					$overlays = newData;
				} else if (kind == 'banners') {
					$banners = newData;
				} else if (kind == 'clips') {
					$clips = newData;
				}
			}
		}, 100);
	}

	async function setStoresFromSync(syncData) {
		// console.log('setStoresFromSync', syncData);
		updateChannelFromStores = false;
		$participants = syncData.p ? syncData.p : [];
		$layout = syncData.l ? syncData.l : 'gap';
		$background = syncData.b ? syncData.b : null;
		$overlay = syncData.o ? syncData.o : null;
		$clip = syncData.c ? syncData.c : null;
		$banner = syncData.x ? syncData.x : null;
		$highlight = syncData.h ? syncData.h : null;
		$broadcasting = syncData.g ? syncData.g : null;
		$broadcastStarted = syncData.z ? syncData.z : false;
		$streamActivated = syncData.y ? syncData.y : null;
		await tick();
		updateChannelFromStores = true;
	}

	function updateChannel() {
		// console.log('updateChannel', $streamActivated);
		if (mounted && studioChannel && updateChannelFromStores) {
			let syncData = {};
			syncData.p = $participants;
			syncData.l = $layout;
			syncData.b = $background;
			syncData.o = $overlay;
			syncData.c = $clip;
			syncData.x = $banner;
			syncData.h = $highlight;
			syncData.g = $broadcasting;
			syncData.z = $broadcastStarted;
			syncData.y = $streamActivated;
			studioChannel.set(syncData);
		}
	}

	function countParticipants() {
		let c = 0;
		for (const p of $participants) {
			if (p.o) {
				c++;
			}
		}
		$participantCount = c;
	}

	function inactivityTimeout() {
		// console.log('inactivityTimeout');
		// Skip if the session is being recorded or is happening now (but reset the timer)
		let skipTimeout = false;
		if ($broadcasting) {
			skipTimeout = true;
		} else if ($currentSessions) {
			for (const s of $currentSessions) {
				if (s.ref == $session.ref) {
					skipTimeout = true;
					break;
				}
			}
		}
		if (skipTimeout) {
			sessionTimeoutId = uuid();
		} else {
			closeRoom();
		}
	}

	function setParticipantOptions() {
		let updated = false;
		for (const [i,p] of $participants.entries()) {
			if (p.id == $attendee.ref) {
				if (p.f != $studioOptions.mirrorCamera) {
					$participants[i].f = $studioOptions.mirrorCamera;
					updated = true;
				}
			}
		}
		if (updated) $participants = $participants;
	}

	function setParticipantSid(track) {
		let updated = false;
		for (const [i,p] of $participants.entries()) {
			if (p.id == $attendee.ref) {
				if ((track.source == 'camera') || (track.source == 'screen_share')) {
					$participants[i].t = track.trackSid;
					updated = true;
				}
			}
		}
		if (updated) $participants = $participants;
	}

	async function getClipTokens(force) {
		if (force || mounted) {
			if (($event.setup.virtual.studio && $event.setup.virtual.studio.clips && $event.setup.virtual.studio.clips.length) || ($clips && $clips.length)) {
				const tokenData = await getServerData('virtual/studio/cliptokens', {
					sessionRef: $session.ref
				});
				$clipTokens = tokenData.clipTokens;
			}
		}
	}

	function logBroadcasting() {
		postServerData('virtual/studio/broadcasting', {
			livestream: $session.livestream
		});
	}

	function exitSession() {
		dispatch('exit');
	}

	function triggerSetTrackOptions() {
		// setTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions, $rtcProvider, $room);
		setTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions, $room);
	}
	
	$: if (providerLoaded && processorsLoaded) {
		setLocalTracks();
	}

	$: countParticipants($participants);
	$: updateChannel($participants, $layout, $background, $overlay, $clip, $banner, $highlight, $broadcasting, $streamActivated);

	// $: triggerSetTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions, $rtcProvider);
	$: triggerSetTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions);

	$: setParticipantOptions($studioOptions);

	$: if ($broadcasting) {
		logBroadcasting();
	}

	// $: console.log('broadcastStarted', $broadcastStarted);

	$: if ($clips || $event) {
		getClipTokens();
	}

	// $: if ($renderKey) {
	// 	cleanupParticipants();
	// 	console.log({$participants});
	// }

</script>

<style>
</style>

{#if $session}
	{#if mounted && $room}
		<CenterPanel on:exit/>
		<LeftPanel/>
		<RightPanel/>
		{#if sessionTimeoutId}
			{#key sessionTimeoutId}
				<InactivityTimeout minutes={90} on:timeout={inactivityTimeout} />
			{/key}
		{/if}
	{:else if providerLoaded && processorsLoaded}
		{#if mounted}
			<Dialog
				text="We’ve disconnected you from this studio session due to inactivity."
				actions={[
					{
						label: "Reconnect",
						dispatch: "confirm"
					},
					{
						label: "Exit",
						ghost: true,
						dispatch: "escape"
					}
				]}
				on:escape={exitSession}
				on:confirm={restoreSession}
			/>
		{:else}
			<Overlay modal={true} wide={true} nested={nestedOverlay} on:escape={exitSession}>
				<Settings greenRoom={true} on:save={initialiseSession} bind:nestedOverlay/>
			</Overlay>
		{/if}
	{/if}
{/if}