<script>

	import { onMount, onDestroy, getContext, tick } from 'svelte';
	import { fade } from "svelte/transition";

	// import { ParticipantEvent } from 'livekit-client';

	import Avatar from "./Avatar.svelte";
	import Button from "../../../ui/Button.svelte";

	// import LivekitAudio from "./LivekitAudio.svelte";

	import { attendees, attendee, event } from '../../../lib/stores.js';
	import { horizontalSlide } from "../../../lib/horizontalSlide.js";
	import { autoblur } from '../../../lib/autoblur.js';
	import { pollAudioLevel } from '../../../lib/pollAudioLevel.js';

	const participants = getContext('participants');
	const room = getContext('room');
	const trackPublications = getContext('trackPublications');
	const layout = getContext('layout');
	const banner = getContext('banner');
	const highlight = getContext('highlight');
	const host = getContext('host');
	const broadcasting = getContext('broadcasting');
	const livestreamStatus = getContext('livestreamStatus');
	// const localVideoTrack = getContext('localVideoTrack');
	// const localAudioTrack = getContext('localAudioTrack');

	export let participant;
	export let i;

	let attendeeId = participant.id;

	let over = false;
	let mounted = false;

	let local = false;
	let screenShare = false;

	let videoElement;
	let videoAttached = false;

	let audioElement;
	let audioAttached = false;

	let invisible = true;
	let muted = false;
	let selfMuted = false;
	let audioLevel = 0;

	let elem;
	let boxWidth = 0;
	let aspectRatio = '16/9';
	let tall = false;

	let livestreamConnected = false;
	let showName = false;
	let showNameTimer;

	onMount(async () => {

		if (participant.id == $attendee.ref) {
			local = true;
			attendeeId = participant.id;
		} else if (participant.id.substring(2) == $attendee.ref) {
			local = true;
			screenShare = true;
			attendeeId = participant.id.substring(2);
		} else {
			if (participant.id.startsWith('s_')) {
				screenShare = true;
				attendeeId = participant.id.substring(2);
			} else {
				attendeeId = participant.id;
			}
		}

		initShowName();

		mounted = true;

	});

	const setAspectRatio = async () => {
		if (screenShare && elem) {
			const w = videoElement.videoWidth;
			const h = videoElement.videoHeight;
			aspectRatio = `${w}/${h}`;
			setTimeout(() => {
				const percent = w / h;
				const slot = elem.parentNode;
				const slotWidth = slot.offsetWidth;
				const slotHeight = slot.offsetHeight;
				const slotPercent = slotWidth / slotHeight;
				tall = (slotPercent > percent) ? true : false;
				// console.log('aspectRatio', aspectRatio, percent, slotPercent, tall);
			}, 100);
		}
	};

	async function rtcTracks() {

		// console.log('rtcTracks');

		await tick();

		if ($trackPublications[participant.id] && $trackPublications[participant.id].video) {
			invisible = $trackPublications[participant.id].video.isMuted ? true : false;
			if (!videoAttached && $trackPublications[participant.id].video.track) {
				// console.log('(stage) attaching video track', participant.id);
				$trackPublications[participant.id].video.track.attach(videoElement);
				videoElement.addEventListener("resize", setAspectRatio);
				videoAttached = true;
			}
		}

		if (!local) {
			if ($trackPublications[participant.id] && $trackPublications[participant.id].audio) {
				if (!audioAttached && $trackPublications[participant.id].audio.track) {
					// console.log('(stage) attaching audio track', participant.id);
					$trackPublications[participant.id].audio.track.attach(audioElement);
					audioAttached = true;
				}
			}
		}

		checkMuted();

		setAspectRatio();

	}

	function toggleMute() {
		if ($trackPublications[participant.id] && $trackPublications[participant.id].audio && $trackPublications[participant.id].audio.isMuted && !$trackPublications[participant.id].audio.isMuted) {
			participant.m = !participant.m;
		}
	}

	function toggleSpotlight() {
		for (const p of $participants) {
			if (p.id == participant.id) {
				p.s = !p.s;
				$participants = $participants;
				break;
			}
		}
	}

	function toggleStatus() {
		for (const p of $participants) {
			if (p.id == participant.id) {
				p.o = false;
				p.s = false;
				$participants = $participants;
				break;
			}
		}
	}

	function checkMuted() {
		let m = false;
		if ($trackPublications[participant.id] && $trackPublications[participant.id].audio) {
			// console.log('checkMuted', {audioTrack});
			if ($trackPublications[participant.id].audio.isMuted) {
				m = true;
				selfMuted = true;
			} else {
				m = false;
				selfMuted = false;
			}
		}
		if (participant.m) m = true;
		muted = m;
	}

	function initShowName() {
		if (screenShare) {
			showName = false;
		} else {
			clearTimeout(showNameTimer);
			if ($event.setup.virtual.studio && $event.setup.virtual.studio.nameTags && $event.setup.virtual.studio.nameTags.display) {
				if ($event.setup.virtual.studio.nameTags.display == 'show') {
					showName = true;
				} else if ($event.setup.virtual.studio.nameTags.display == 'hide') {
					showName = false;
				} else if ($event.setup.virtual.studio.nameTags.display == 'timed') {
					showName = true;
					showNameTimer = setTimeout(() => {
						showName = false;
					}, 30000);
				}
			}
		}
	}

	function handleKeydown(e) {
		if (over && host) {
			if (e.key === 's') {
				toggleSpotlight();
			}
		}
	}

	$: if (mounted && $room && $trackPublications) {
		rtcTracks();
	}

	$: checkMuted(participant);

	$: if ($layout || $participants) {
		setAspectRatio();
	}

	$: if (($livestreamStatus != 'idle') && !livestreamConnected) {
		livestreamConnected = true;
	}

	$: if ($broadcasting && livestreamConnected) {
		initShowName();
	}

</script>

<style>

	:global(.layout.side) .video.screenShare,
	:global(.layout.cinema) .video.screenShare,
	:global(.layout.p1) .video.screenShare {
		aspect-ratio: var(--ratio);
	}

	:global(.layout.p1) .video.screenShare {
		width: 100%;
		height: auto;
	}

	:global(.layout.side) .video.screenShare.tall,
	:global(.layout.cinema) .video.screenShare.tall,
	:global(.layout.p1) .video.screenShare.tall {
		width: unset;
		min-width: unset;
		height: 100%;
	}

	.video video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.video video.blur {
		top: -2%;
		left: -2%;
		width: 104%;
		height: 104%;
	}

	.video video.mirror {
		transform: scale(-1,1);
	}

	.video:after {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 2;
	}

	.video .nameTag {
		position: absolute;
		left: calc(var(--sw) * 0.01);
		bottom: calc(var(--sw) * 0.01);
		box-sizing: border-box;
		max-width: calc(100% - ((var(--sw) * 0.01) * 2));
		line-height: 1;
		z-index: 10;
	}

	:global(.layout.cinema:not(.p1).hasBanner .slot:nth-of-type(1)) .video .nameTag {
		transition: bottom 0.2s ease;
		bottom: calc(100% - ((var(--sh) - var(--bh) - ((var(--sw) * 0.016) * 4.4)) - (((var(--sw) / 4) - (var(--sw) * 0.032)) * 0.5625)) + (var(--sw) * 0.005));
	}

	:global(.layout.cinema.p6.hasBanner .slot:nth-of-type(1)) .video .nameTag {
		bottom: calc(100% - ((var(--sh) - var(--bh) - ((var(--sw) * 0.016) * 4.4)) - (((var(--sw) / 5) - (var(--sw) * 0.032)) * 0.5625)) + (var(--sw) * 0.005));
	}

	.video .nameTag:before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: calc(var(--sw) * 0.002);
		background-color: var(--nameTagBgColor);
		opacity: var(--nameTagBgOpacity);
		z-index: 0;
		box-shadow: 0 0 calc(var(--sw) * 0.002) rgba(0, 0, 0, 0.3);
		transition: background-color 0.4s ease, opacity 0.4s ease;
	}

	.video .nameTag > span {
		position: relative;
		z-index: 1;
		display: block;
		line-height: 1;
		font-size: calc(var(--sw) * 0.016);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--nameTagTextColor);
		font-family: var(--nameTagFont);
		transition: color 0.4s ease;
		padding: calc(var(--sw) * 0.008) calc(var(--sw) * 0.01);
	}

	:global(.layout.inset .slot:nth-of-type(1)) .video .nameTag {
		top: calc(var(--sw) * 0.01);
		bottom: auto;
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button[disabled],
	.actions button[disabled]:hover,
	.actions button[disabled]:active,
	.actions button[disabled]:focus {
		background: var(--panelColor);
		cursor: not-allowed;
	}
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
	.actions button.dots svg {
		width: 0.9rem;
		height: 0.9rem;
	}
	.actions button.muted {
		color: var(--red);
	}
	.actions button.lit {
		background: var(--accentColor);
		color: var(--panelColor);
		border-color: var(--blend-20);
	}
	.actions button.lit:hover,
	.actions button.lit:active,
	.actions button.lit:focus {
		background: var(--accentBlend-80);
	}

	.video.invisible {
		background: var(--blend-120);
	}
	.video.invisible video {
		opacity: 0;
	}
	.video :global(.avatar) {
		z-index: 10;
		top: 50%;
		left: 50%;
		width: 20%;
		height: auto;
		aspect-ratio: 1 / 1;
		transform: translate(-50%,-50%);
	}
	.video :global(.avatar abbr) {
		font-size: calc(var(--boxWidth) * 0.1);
	}
	.video.invisible:before {
		content: '';
		z-index: 9;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20%;
		height: auto;
		aspect-ratio: 1 / 1;
		transform: translate(-50%,-50%) scale(calc((var(--audioLevel) / 20) + 1));
		transition: transform 0.2s ease;
		background: var(--panelColor);
		opacity: 0.4;
		border-radius: 100%;
	}

</style>

<svelte:window on:keydown={handleKeydown}/>

{#if mounted}
	<div
		class="video"
		class:over={over}
		class:invisible
		class:tall
		class:screenShare={screenShare && (i == 0)}
		on:mouseenter={() => { over = true }}
		on:mouseleave={() => { over = false }}
		bind:clientWidth={boxWidth}
		bind:this={elem}
		style="--boxWidth:{boxWidth}px;--audioLevel:{audioLevel};--ratio:{aspectRatio};"
	>

		<!-- svelte-ignore a11y-media-has-caption -->
		<video id="video-{participant.id}" bind:this={videoElement} class:mirror={participant.f && !screenShare} class:blur={participant.b && !screenShare} />

		{#if invisible}
			<Avatar {attendeeId} {invisible} {screenShare} bind:audioLevel />
		{/if}

		{#if !local}
			<audio id="audio-{participant.id}" bind:this={audioElement} />
		{/if}

		{#if showName && $attendees[attendeeId] && !(participant.s && ($banner || $highlight))}
			<span class="nameTag" in:horizontalSlide={{ duration: 400, delay: 400 }} out:horizontalSlide|local={{ duration: 400 }}>
				<span>{$attendees[attendeeId].f} {$attendees[attendeeId].l}</span>
			</span>
		{/if}

		{#if over && host}
			<div class="actions" transition:fade|local={{ duration: 100 }}>
				<button
					type="button"
					use:autoblur
					on:click|stopPropagation={toggleStatus}
				>
					<svg viewBox="0 0 20 20">
						<path d="M10 3.5c3.58 0 6.5 2.92 6.5 6.5s-2.92 6.5-6.5 6.5-6.5-2.92-6.5-6.5 2.92-6.5 6.5-6.5m0-1c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zM13.99 6.86l-3.14 3.14 3.14 3.14-.86.86-3.14-3.14-3.15 3.14-.84-.84 3.14-3.14-3.14-3.15.86-.86 3.14 3.14 3.14-3.14.85.85z"/>
					</svg>
				</button>
				{#if $trackPublications[participant.id] && $trackPublications[participant.id].audio}
					<button
						type="button"
						class:muted={muted}
						use:autoblur
						on:click|stopPropagation={toggleMute}
						disabled={selfMuted}
					>
						<svg viewBox="0 0 20 20">
							{#if muted}
								<path d="M15 9.94v-1.61h-1v1.61c0 .66-.19 1.27-.51 1.8l.72.73c.51-.73.79-1.6.79-2.53zM3.8 3.46l-.7.7 3.7 3.7v1.36c0 1.77 1.44 3.2 3.2 3.2.4 0 .78-.08 1.15-.22l.88.88c-.46.22-.97.35-1.51.35h-1.03c-1.93 0-3.49-1.57-3.49-3.49v-1.62h-1v1.61c0 2.47 2.02 4.49 4.49 4.49h.01v1.8h-1.3v1h3.61v-1h-1.31v-1.8h.02c.82 0 1.59-.23 2.24-.61l3.08 3.08.7-.7-12.74-12.73zm6.2 7.96c-1.22 0-2.2-.99-2.2-2.2v-.36l2.53 2.53-.33.03zM9.99 2.79c-1.48 0-2.71 1.01-3.08 2.37l.89.88v-.02l-.01-.04c0-1.22.99-2.2 2.21-2.2 1.09 0 2 .8 2.16 1.86l.04 3.61c0 .34-.1.67-.24.96l.73.74c.32-.49.5-1.09.5-1.72v-3.24c.01-1.77-1.42-3.2-3.2-3.2z"/>
							{:else}
								<path d="M10 12.42c1.77 0 3.2-1.43 3.2-3.2v-3.24c0-1.76-1.43-3.2-3.2-3.2-1.77 0-3.2 1.43-3.2 3.2v3.24c0 1.76 1.43 3.2 3.2 3.2zm2.2-3.18c-.01 1.2-.99 2.19-2.2 2.19-1.22 0-2.2-.99-2.2-2.2v-3.25c0-1.22.99-2.2 2.2-2.2 1.1 0 2 .8 2.16 1.86l.04 3.6zm-2.2-2 0-1.8"/><path d="M14 8.32v1.61c0 1.93-1.57 3.49-3.49 3.49h-1.03c-1.93 0-3.49-1.57-3.49-3.49v-1.61h-1v1.61c0 2.48 2.02 4.5 4.49 4.5h.02v1.8h-1.3v1h3.61v-1h-1.31v-1.8h.02c2.47 0 4.49-2.02 4.49-4.49v-1.62h-1.01z"/>
							{/if}
						</svg>
					</button>
				{/if}
				<button type="button" class:lit={participant.s} use:autoblur on:click|stopPropagation={toggleSpotlight}>
					<svg viewBox="0 0 20 20">
						{#if participant.s}
							<path d="M17.01 14.77l-.04-.06-11.52-11.94c-.19-.2-.51-.2-.71-.01-.2.19-.2.51-.01.71l9.78 10.14c-1.04-.24-2.31-.38-3.68-.38-2.39 0-4.47.42-5.57 1.05l-1.49-9.9c-.04-.28-.29-.46-.56-.42-.28.04-.47.29-.42.56l1.62 10.84.05.16c.35 1.04 3.06 1.85 6.36 1.85 3.53 0 6.4-.93 6.4-2.07 0-.18-.07-.36-.21-.53z"/>
						{:else}
							<path d="M17.01 14.77l-.04-.06-11.52-11.94c-.19-.2-.51-.2-.71-.01-.2.19-.2.51-.01.71l9.78 10.14c-1.04-.24-2.31-.38-3.68-.38-2.39 0-4.47.42-5.57 1.05l-1.49-9.9c-.04-.28-.29-.46-.56-.42-.28.04-.47.29-.42.56l1.62 10.84.06.16c.35 1.04 3.06 1.85 6.36 1.85 3.53 0 6.4-.93 6.4-2.07-.01-.18-.08-.36-.22-.53zm-6.19 1.61c-3.03 0-4.83-.68-5.31-1.07.48-.39 2.29-1.07 5.31-1.07 3.03 0 4.83.68 5.31 1.07-.48.39-2.28 1.07-5.31 1.07z"/>
						{/if}
					</svg>
				</button>
			</div>
		{/if}
	</div>

{/if}