<script>

	import { onMount, onDestroy } from 'svelte';

	import { setDriftlessInterval, clearDriftless } from 'driftless';

	import {	epoch } from '../lib/stores.js';

	export let started = $epoch;

	let counter;
	let elapsed = 0;

	let days, hours, totalHours, minutes, totalMinutes, seconds;

	onMount(() => {
		counter = setDriftlessInterval(() => {
			elapsed = $epoch - started;
		}, 1000);
	});

	onDestroy(() => {
		clearDriftless(counter);
	});

	$: days = String(Math.floor(elapsed / 60 / 60 / 24));

	$: hours = String(Math.floor((elapsed / 60 / 60) - (days * 24))).padStart(2, '0');
	$: totalHours = String(Math.floor(elapsed / 60 / 60)).padStart(2, '0');

	$: minutes = String(Math.floor((elapsed - (totalHours * 60 * 60)) / 60)).padStart(2, '0');
	$: totalMinutes = String(Math.floor(elapsed / 60)).padStart(2, '0');

	$: seconds = String(Math.floor(elapsed - totalMinutes * 60)).padStart(2, '0');

</script>

<style>
	span.timer {
		font-family: system-ui;
		font-variant-numeric: tabular-nums;
	}
</style>

{#if elapsed}
	<span class="timer">
		{#if days > 1}{days} days, {:else if days > 0}{days} day, {/if}
		{#if hours > 0}{hours}:{/if}{minutes}:{seconds}
	</span>
{:else}
	<span class="timer">00:00</span>
{/if}
