import { createLocalTracks, createLocalVideoTrack, createLocalAudioTrack, VideoPresets } from 'livekit-client';
import { ProcessorWrapper, BackgroundTransformer, BackgroundBlur } from '@livekit/track-processors';

import * as Sentry from "@sentry/browser";

export async function getLocalTracks(videoDevice, audioDevice) {
	let localTrackParams = {};
	if (videoDevice) {
		localTrackParams.video = {
			deviceId: {
				exact: videoDevice
			},
			resolution: VideoPresets.h720.resolution,
		};
	} else {
		localTrackParams.video = {
			resolution: VideoPresets.h720.resolution,
		};
	}
	if (audioDevice) {
		localTrackParams.audio = {
			deviceId: {
				exact: audioDevice
			}
		};
	}
	let [localAudioTrack,localVideoTrack] = await createLocalTracks(localTrackParams);
	return [localVideoTrack, localAudioTrack];
}

// export async function getLocalVideoTrack(videoDevice) {

// 	if (!videoDevice) return;

// 	let localVideoTrack = await createLocalVideoTrack({
// 		deviceId: {
// 			exact: videoDevice
// 		},
// 		resolution: VideoPresets.h720.resolution,
// 	});

// 	return localVideoTrack;

// }

// export async function getLocalAudioTrack(audioDevice) {

// 	if (!audioDevice) return;

// 	let localAudioTrack = await createLocalAudioTrack({
// 		deviceId: {
// 			exact: audioDevice
// 		},
// 	});

// 	return localAudioTrack;

// }

export function blurIsSupported() {
	return ProcessorWrapper.isSupported && BackgroundTransformer.isSupported;
}

export async function setTrackOptions(localVideoTrack, localAudioTrack, options, room) {

	if (localVideoTrack) {

		let currentProcessor = localVideoTrack.getProcessor();
		if (currentProcessor) currentProcessor = currentProcessor.name;

		if ((currentProcessor != 'background-blur') && options.blurBackground) {
			// console.log('adding BackgroundBlur');
			const blur = BackgroundBlur(14);
			await localVideoTrack.setProcessor(blur);
		} else if (currentProcessor && !options.blurBackground) {
			await localVideoTrack.stopProcessor();
		}

		if (options.videoDevice) {

			let currentVideoDeviceId = await localVideoTrack.getDeviceId();
			// console.log('videoDevice', currentVideoDeviceId, options.videoDevice);

			if (currentVideoDeviceId != options.videoDevice) {
				await localVideoTrack.setDeviceId({
					exact: options.videoDevice
				});
			}

		}

	}

	if (localAudioTrack) {

		if (options.audioDevice) {

			let currentDeviceId = await localAudioTrack.getDeviceId();
			// console.log('audioDevice', currentDeviceId, options.audioDevice);

			if (currentDeviceId != options.audioDevice) {
				await localAudioTrack.setDeviceId({
					exact: options.audioDevice
				});
			}

		}

	}

}

export async function getMediaDevices() {

	let videoDevices = [];
	let audioDevices = [];

	try {

		const mediaDevices = await navigator.mediaDevices.enumerateDevices();

		mediaDevices.forEach((d) => {
			if ((d.kind == 'videoinput') && d.deviceId) {
				videoDevices.push({
					value: d.deviceId,
					label: d.label
				});
			} else if ((d.kind == 'audioinput') && d.deviceId) {
				audioDevices.push({
					value: d.deviceId,
					label: d.label
				});
			}
		});

	} catch (e) {

		Sentry.captureMessage(`getMediaDevices :: ${e.name} :: ${e.message}`, 'warning');

	}

	return [videoDevices, audioDevices];

}