<script>

	import { onMount, onDestroy, getContext } from 'svelte';
	import { writable } from 'svelte/store';

	import Avatar from "../../../ui/Avatar.svelte";

	import { attendees } from '../../../lib/stores.js';
	import { pollAudioLevel } from '../../../lib/pollAudioLevel.js';

	const trackPublications = getContext('trackPublications');

	export let attendeeId;
	export let invisible;
	export let screenShare;
	export let audioLevel = 0;

	let active = writable(true);

	let ready = false;

	onMount(() => {
		if (invisible && $trackPublications[attendeeId] && $trackPublications[attendeeId].audio && $trackPublications[attendeeId].audio.track && !screenShare) {
			pollAudioLevel($trackPublications[attendeeId].audio.track, active, level => {
				if (level > 6) {
					audioLevel = level;
				} else if (level > 3) {
					audioLevel = level / 2;
				} else {
					audioLevel = 0;
				}
			});
		}
	});

	onDestroy(() => {
		$active = false;
	});

	$: if ($attendees[attendeeId]) {
		ready = true;
	}

</script>

{#if ready}
	<Avatar identity={$attendees[attendeeId]} hasMenu={false} />
{/if}