<script>

	import { getContext } from 'svelte';

	import { displayInEventTz, session, epoch, leftDocked } from '../../lib/stores.js';
	import {	showDate } from '../../lib/dt.js';
	import {	autoblur } from '../../lib/autoblur.js';

	import Presence from '../../ui/Presence.svelte';

	const host = getContext('host');
	const broadcasting = getContext('broadcasting');
	const livestreamStatus = getContext('livestreamStatus');
	const renderKey = getContext('renderKey');
	const room = getContext('room');

	let refreshing = false;

	function refreshRender() {
		refreshing = true;
		$renderKey = $epoch;
		// if ($room) {
		// 	$room.localParticipant.republishAllTracks();
		// }
		setTimeout(async () => {
			refreshing = false;
		}, 500);
	}

</script>

<style>
	.wrap {
		flex: 0 0 auto;
		padding: 0.75rem 1rem 1rem 0.75rem;
		background: var(--blend-10);
	}
	h2 {
		font-size: 0.9rem;
		margin-bottom: 0.5rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
	}
	.wrap > div {
		display: flex;
		gap: 1rem;
		justify-content: space-between;
	}
	.wrap > div :global(p) {
		font-size: 0.6875rem;
		color: var(--blend-80);
		line-height: 1;
	}

	button {
		flex: 0 0 1.2rem;
		width: 1.2rem;
		height: 1.2rem;
		color: var(--textColor);
		cursor: pointer;
		opacity: 0.2;
    	transition: opacity 0.2s ease-in-out;
    	padding: 0;
    	border: 0;
    	position: relative;
    	top: -0.1rem;
    	right: -0.15rem;
   }
	button:hover {
		opacity: 1;
	}

	@keyframes refresh-spin {
		from { transform: rotate(0deg); }
		to { transform: rotate(-180deg); }
	}

	button.refreshing svg {
		animation-name: refresh-spin;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-duration: 0.5s;
	}
</style>

{#if $session}
	<div class="wrap">
		<div>
			<h2 title={$session.name}>{$session.name}</h2>
			<button type="button" on:click={refreshRender} use:autoblur class:refreshing>
				<svg viewBox="0 0 80 80"><title>Refresh</title><path d="M10.65 50.68l11.03 9.19 2.56-14.13-4.91 1.79c-4.15-11.4 1.75-24.05 13.15-28.2 5.52-2.01 11.5-1.75 16.82.73l1.69-3.62c-6.3-2.94-13.36-3.24-19.88-.87-13.47 4.9-20.44 19.85-15.54 33.32l-4.92 1.79zM69.35 29.32l-11.03-9.19-2.56 14.13 4.91-1.79c2.01 5.52 1.75 11.5-.73 16.82s-6.89 9.37-12.41 11.38c-5.52 2.01-11.5 1.75-16.82-.74l-1.69 3.62c3.5 1.63 7.23 2.45 10.97 2.45 3 0 6.01-.53 8.91-1.58 6.53-2.38 11.74-7.15 14.67-13.44 2.94-6.29 3.24-13.35.87-19.88l4.91-1.78z"/></svg>
			</button>
		</div>
		<div>
			<p class="date">{showDate($session.starts, 'ddd DD MMM, HH:mm')}–{showDate($session.ends, 'HH:mm')}</p>
			{#if !$leftDocked}
				<Presence/>
			{/if}
		</div>
	</div>
{/if}