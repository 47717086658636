import { get } from 'svelte/store';

function rootMeanSquare(samples) {
  const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
  return Math.sqrt(sumSq / samples.length);
}

export const pollAudioLevel = async (track, active, onLevelChanged) => {

  // active is a store
  // if we set to false, the loop stops

  if (!track) {
    // console.log("pollAudioLevel -- no track...");
    return;
  }

  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = AudioContext ? new AudioContext() : null;

  if (!audioContext) {
    // console.log("Can't get AudioContext");
    return;
  }

  // Due to browsers' autoplay policy, the AudioContext is only active after
  // the user has interacted with your app, after which the Promise returned
  // here is resolved.
  await audioContext.resume();

  // Create an analyser to access the raw audio samples from the microphone.
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 1024;
  analyser.smoothingTimeConstant = 0.6;

  // Connect the track's media source to the analyser.
  const stream = new MediaStream([track.mediaStreamTrack]);
  const source = audioContext.createMediaStreamSource(stream);
  source.connect(analyser);

  const samples = new Uint8Array(analyser.frequencyBinCount);
  let level = null;

  // Periodically calculate the audio level from the captured samples,
  // and if changed, call the callback with the new audio level.
  requestAnimationFrame(function checkLevel() {

    let ok = get(active);
    if (!ok) {
      // console.log('audio polling no longer active...')
      return;
    }

    if (track.mediaStreamTrack.readyState === 'live') {

      analyser.getByteFrequencyData(samples);
      
      const rms = rootMeanSquare(samples);
      const log2Rms = rms && Math.log2(rms);

      // Audio level ranges from 0 (silence) to 10 (loudest).
      const newLevel = Math.ceil(10 * log2Rms / 8);

      if (level !== newLevel) {
        level = newLevel;
        onLevelChanged(level);
      }

    } else {
      // console.log('Bad readyState...');
      onLevelChanged(0);
    }

    requestAnimationFrame(checkLevel);

  });

}