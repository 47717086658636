<script>
	
	import { onMount, onDestroy, getContext, createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	import { createLocalScreenTracks } from 'livekit-client';

	import Button from "../../ui/Button.svelte";
	import Overlay from "../../ui/Overlay.svelte";
	import Settings from "../Settings.svelte";

	import { attendee, room, studioOptions } from '../../lib/stores.js';

	const dispatch = createEventDispatcher();

	const participants = getContext('participants');
	const rtcRoom = getContext('room');
	const localVideoTrack = getContext('localVideoTrack');
	const localAudioTrack = getContext('localAudioTrack');
	const audienceViewMuted = getContext('audienceViewMuted');
	const trackPublications = getContext('trackPublications');

	let invisible = false;
	let muted = false;

	let mediaStream;
	// let screenVideoTrack;
	// let screenAudioTrack;

	let localParticipant;

	let flyY = 200;

	let optionsOverlay = false;
	let nestedOverlay = false;

	let priorMutedState = muted;

	const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

	if (vw < 1101) {
		flyY = 20;
	}

	onMount(() => {
		let publications = $rtcRoom.localParticipant.getTrackPublications();
		for (const t of publications) {
			if (t.source == 'screen_share') {
				// screenVideoTrack = t;
				if (!mediaStream) mediaStream = [];
				mediaStream.push(t.track.mediaStreamTrack);
			} else if (t.source == 'screen_share_audio') {
				// screenAudioTrack = t;
				if (!mediaStream) mediaStream = [];
				mediaStream.push(t.track.mediaStreamTrack);
			}
		}
	});

	onDestroy(() => {
		stopMediaStream();
	});

	function exitStudio() {
		dispatch('exit');
	}

	async function toggleMute(m) {
		if ($rtcRoom) {
			if ($localAudioTrack) {
				if (m) {
					await $localAudioTrack.mute();
				} else {
					await $localAudioTrack.unmute();
				}
			}
		}
	}

	async function toggleCamera(m) {
		if ($rtcRoom) {
			if ($localVideoTrack) {
				// await $rtcRoom.localParticipant.setCameraEnabled(!m);
				if (m) {
					await $localVideoTrack.mute();
				} else {
					await $localVideoTrack.unmute();
				}
			}
		}
	}

	async function shareScreen() {
		if (!mediaStream) {

			const shareAudio = $localAudioTrack ? true : false;

			try {
				mediaStream = await createLocalScreenTracks({
					audio: shareAudio,
					surfaceSwitching: "include",
				});
			} catch (err) {
				console.error(err);
			}

			if (mediaStream) {

				// console.log({mediaStream});

				if (Array.isArray(mediaStream)) {
					mediaStream.forEach(async (t) => {
						console.log('publishing screen share track from array', t.source);
						if (t.kind == 'video') {
							// screenVideoTrack = await $rtcRoom.localParticipant.publishTrack(t);
							$rtcRoom.localParticipant.publishTrack(t);
						} else if (t.kind == 'audio') {
							// screenAudioTrack = await $rtcRoom.localParticipant.publishTrack(t);
							$rtcRoom.localParticipant.publishTrack(t);
						}
					});
				} else {
					console.log('publishing screen share track', mediaStream.source);
					// screenVideoTrack = await $rtcRoom.localParticipant.publishTrack(mediaStream);
					$rtcRoom.localParticipant.publishTrack(mediaStream);
				}

				console.log('published tracks', $rtcRoom.localParticipant.trackPublications);

			}

		} else {

			stopMediaStream();

			// mediaStream = null;

			// let filtered = $participants.filter(p => p.id != 's_' + $attendee.ref);
			// $participants = filtered;

		}
	}

	async function stopMediaStream() {
		if (mediaStream) {

			// console.log('controls stopMediaStream');

			if ($rtcRoom && $trackPublications['s_' + $attendee.ref] && $trackPublications['s_' + $attendee.ref].video && $trackPublications['s_' + $attendee.ref].video.track) {
				await $rtcRoom.localParticipant.unpublishTrack($trackPublications['s_' + $attendee.ref].video.track, true);
			}

			if ($rtcRoom && $trackPublications['s_' + $attendee.ref] && $trackPublications['s_' + $attendee.ref].audio && $trackPublications['s_' + $attendee.ref].audio.track) {
				await $rtcRoom.localParticipant.unpublishTrack($trackPublications['s_' + $attendee.ref].audio.track, true);
			}

			// if (Array.isArray(mediaStream)) {
			// 	mediaStream.forEach(t => {
			// 		if (t) t.stop();
			// 	});
			// } else if (mediaStream) {
			// 	mediaStream.stop();
			// }

			mediaStream = null;

		}
	}

	function getLocalParticipant() {
		let isSharing = false;
		for (const p of $participants) {
			if (p.id == $attendee.ref) {
				localParticipant = p;
			} else if (p.id.substring(2) == $attendee.ref) {
				isSharing = true;
			}
		}
		if (!isSharing) {
			// Local screenshare particpant has been removed (through an unpublish event)
			// So switch off the button to match
			stopMediaStream();
		}
	}

	function autoMute() {
		if ($audienceViewMuted) {
			muted = priorMutedState;
			toggleMute(muted);
		} else {
			muted = true;
			toggleMute(muted);
		}
	}

	function saveOptions() {
		optionsOverlay = false;
		$participants = $participants;
	}

	$: toggleMute(muted);
	$: toggleCamera(invisible);

	$: getLocalParticipant($participants);

	$: autoMute($audienceViewMuted);

</script>

<style>
	.outer {
		flex: 1 0 auto;
		position: relative;
		min-height: 5rem;
	}

	@media (max-height: 599px) {
		.outer {
			min-height: 4rem;
		}
	}

	.controls {
		background: #fff;
/*		border-radius: 8px;*/
		border-radius: 4px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		padding: 0.4rem 1rem;
		display: flex;
		gap: 0.8rem;
		box-shadow: 0 2px 10px -2px var(--shadow);
	}

	.controls :global(button) {
		border-radius: 4px;
		padding: 0;
		width: 3rem;
		height: 3rem;
		position: relative;
		transition: transform 0.2s ease, opacity 0.2s ease;
		border: 0 !important;
	}
	.controls :global(button svg) {
		position: absolute;
		top: 0.2rem;
		left: 0.2rem;
		width: 2.6rem;
		height: 2.6rem;
		margin: unset;
	}

	/*.controls :global(button:hover),
	.controls :global(button:active),
	.controls :global(button:focus) {
		transform: scale(1.15);
	}*/

	.controls :global(button.ghost),
	.controls :global(button.red),
	.controls :global(button.ghost:hover),
	.controls :global(button.ghost:active),
	.controls :global(button.ghost:focus) {
		color: var(--textColor);
		border: 0;
		background: transparent;
	}
	.controls :global(button.ghost:hover),
	.controls :global(button.ghost:active),
	.controls :global(button.ghost:focus) {
		color: var(--textColor);
		background: var(--blend-10);
	}

	.controls :global(button.red) {
		color: var(--red);
	}

	.controls :global(button.red .st0) {
		fill: #fff;
	}

	.controls :global(button.red:hover),
	.controls :global(button.red:active),
	.controls :global(button.red:focus) {
		color: var(--red);
		background: var(--blend-20);
	}

	.controls :global(button.ghost[disabled]) {
		background: transparent !important;
		border: none !important;
	}
</style>

<div class="outer" in:fly={{ duration: 800, delay: 200, y: flyY }}>
	<div class="controls">

		{#if $localAudioTrack}
			<Button tooltip={muted ? "Unmute" : "Mute"} ghost={true} red={muted} disabled={(localParticipant && localParticipant.m) || !$audienceViewMuted} on:click={() => { muted = !muted; priorMutedState = muted; }}>
				<svg viewBox="0 0 48 48">
					{#if muted}
						<path d="M28.42 22.49c0 .69-.19 1.34-.48 1.92l1.47 1.47c.63-.99 1-2.17 1-3.43v-8.48c0-3.53-2.87-6.4-6.41-6.4-3.53 0-6.4 2.87-6.4 6.4v.1l2 2v-2.11c0-2.43 1.98-4.4 4.41-4.4 2.19 0 4 1.61 4.33 3.71l.08 9.22zM34.02 23.87v-3.22h-2v3.22c0 1.32-.37 2.55-1.01 3.6l1.45 1.45c.98-1.44 1.56-3.18 1.56-5.05zM10.93 10.22l-1.41 1.41 8.09 8.08v2.72c0 3.53 2.87 6.4 6.4 6.4.81 0 1.57-.16 2.29-.43l1.75 1.75c-.92.44-1.94.7-3.02.7h-2.05c-3.85 0-6.99-3.14-6.99-6.99v-3.22h-2v3.22c0 4.95 4.03 8.99 8.99 8.99h.03v5.59h-2.6v2h7.21v-2h-2.61v-5.59h.03c1.64 0 3.17-.45 4.49-1.22l6.85 6.85 1.41-1.41-26.86-26.85zm13.08 16.62c-2.44 0-4.41-1.98-4.41-4.4v-.73l5.06 5.06c-.21.04-.42.07-.65.07z"/>
					{:else}
						<path d="M24 28.8c3.5 0 6.4-2.9 6.4-6.4v-8.4c0-3.5-2.9-6.4-6.4-6.4-3.5 0-6.4 2.9-6.4 6.4v8.5c0 3.5 2.9 6.3 6.4 6.3zm4.4-6.3c0 2.4-2 4.4-4.4 4.4-2.4 0-4.4-2-4.4-4.4v-8.5c0-2.4 2-4.4 4.4-4.4 2.2 0 4 1.6 4.3 3.7l.1 9.2zM32 20.6v3.2c0 3.9-3.1 7-7 7h-2c-3.9 0-7-3.1-7-7v-3.2h-2v3.2c0 4.9 4 9 9 9v5.6h-2.6v2h7.2v-2h-2.6v-5.6c4.9 0 9-4 9-9v-3.2h-2z"/>
					{/if}
				</svg>
			</Button>
		{/if}

		{#if $localVideoTrack}
			<Button tooltip={invisible ? "Start camera" : "Stop camera"} ghost={true} red={invisible} on:click={() => { invisible = !invisible }}>
				<svg viewBox="0 0 48 48">
					{#if invisible}
						<path d="M42.39 14.24c-.29-.18-.66-.2-.97-.04l-7.69 3.85h-4.89v-1.81c0-1.95-1.37-3.48-3.12-3.48h-9.92l1.99 1.99h7.56c.83 0 1.5.67 1.5 1.5v7.56l2.19 2.19v-5.96h3.26v7.88h-1.34l2.01 2.01h.76l7.69 3.85c.14.07.29.11.45.11.18 0 .37-.05.53-.15.29-.18.47-.5.47-.85v-17.8c-.01-.35-.18-.67-.48-.85zm-7.98 5.69l6.45-3.23v14.55l-6.45-3.23-.11-.03v-8.03l.11-.03zM30.15 29.93l-2.09-2.09-13.09-13.08-1.99-1.99-4.6-4.6-1.42 1.4 3.19 3.19h-1.9c-1.75 0-3.12 1.53-3.12 3.48v15.51c0 1.95 1.37 3.48 3.12 3.48h16.97c1.75 0 3.62-1.53 3.62-3.48v-.31l7.48 7.48 1.41-1.41-7.58-7.58zm-3.3 1.8c-.01.83-.68 1.5-1.51 1.5h-16.7c-.83 0-1.5-.67-1.5-1.5v-15.47c0-.83.67-1.5 1.5-1.5h3.51l3.14 3.14c-.15.26-.25.56-.25.89 0 .97.79 1.76 1.76 1.76.33 0 .63-.09.89-.25l9.16 9.16v2.27z"/><circle cx="11.54" cy="18.78" r="1.76"/>
					{:else}
						<circle cx="11.54" cy="18.78" r="1.76"/><circle cx="16.8" cy="18.78" r="1.76"/><path d="M42.39 14.24c-.29-.18-.66-.2-.97-.04l-7.69 3.85h-4.89v-1.81c0-1.95-1.37-3.48-3.12-3.48h-17.47c-1.75 0-3.12 1.53-3.12 3.48v15.51c0 1.95 1.37 3.48 3.12 3.48h16.97c1.75 0 3.62-1.53 3.62-3.48v-1.82h4.89l7.69 3.85c.14.07.29.11.45.11.18 0 .37-.05.53-.15.29-.18.47-.5.47-.85v-17.8c-.01-.35-.18-.67-.48-.85zm-15.55 17.5c0 .83-.67 1.5-1.5 1.5h-16.7c-.83 0-1.5-.67-1.5-1.5v-15.49c0-.83.67-1.5 1.5-1.5h16.71c.83 0 1.5.67 1.5 1.5l-.01 15.49zm5.46-3.82h-3.26v-7.88h3.26v7.88zm8.56 3.33l-6.45-3.23-.11-.03v-8.03l.11-.03 6.45-3.23v14.55z"/>
					{/if}
				</svg>
			</Button>
		{/if}

		{#if $localVideoTrack}
			<Button tooltip={mediaStream ? "Stop screen sharing" : "Start screen sharing"} ghost={true} on:click={shareScreen}>
				<svg viewBox="0 0 48 48">
					{#if mediaStream}
						<path d="M8 11c0-1.1.9-2 2-2h28c1.1 0 2 .9 2 2v22c0 1.1-.9 2-2 2h-28c-1.1 0-2-.9-2-2v-22zm29 22.01c.55 0 1-.45 1-1v-20.02c0-.55-.45-1-1-1h-26c-.55 0-1 .45-1 1v20.01c0 .55.45 1 1 1h26zM29.97 39h-12c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1zM18.99 15.31l4.99 4.98 4.99-4.98 1.72 1.72-4.99 4.98 4.98 4.99-1.68 1.69-4.99-4.99-4.99 4.99-1.72-1.72 4.99-4.99-4.99-4.98 1.69-1.69z"/>
					{:else}
						<path d="M8 11c0-1.1.9-2 2-2h28c1.1 0 2 .9 2 2v22c0 1.1-.9 2-2 2h-28c-1.1 0-2-.9-2-2v-22zm29 22.01c.55 0 1-.45 1-1v-20.02c0-.55-.45-1-1-1h-26c-.55 0-1 .45-1 1v20.01c0 .55.45 1 1 1h26zM29.97 39h-12c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1zM14.56 29.75c-.24 0-.48-.06-.7-.19-.44-.26-.67-.78-.63-1.29.71-10.05 8.79-10.63 12.6-10.65v-1.97c0-.43.17-.86.51-1.12.54-.42 1.28-.36 1.75.08l6.26 5.91c.27.25.42.61.42.97s-.15.72-.42.97l-6.21 5.84c-.31.29-.74.46-1.16.39-.68-.11-1.14-.68-1.14-1.32v-2.04c-3.5-.05-7.5-.12-10.15 3.82-.26.38-.69.6-1.13.6zm11.53-10.13c-3.18 0-8.77.24-10.43 6.19-.03.12.11.21.21.12 3.09-2.68 6.96-2.63 9.93-2.59l1.04.01c.55 0 1 .45 1 1v.93c0 .22.26.33.42.18l4.02-3.78c.1-.1.11-.27 0-.36l-4.02-3.79c-.16-.15-.42-.04-.42.18v.92c0 .55-.45 1-1 1h-.34l-.41-.01z"/>
					{/if}
				</svg>
			</Button>
		{/if}

		<Button tooltip="Settings" ghost={true} on:click={() => { optionsOverlay = true }}>
			<svg viewBox="0 0 48 48"><path d="M16.62 10.59c-.32.08-.62.23-.9.44l-2.52 2.01c-.86.68-1.13 1.87-.66 2.86l1 2.08c-.61 1.05-1.06 2.17-1.33 3.33l-2.12.83c-1.02.4-1.64 1.46-1.47 2.54l.48 3.19c.16 1.09 1.06 1.92 2.15 2l2.29.18c.32.58.66 1.09 1.05 1.57.35.43.73.83 1.17 1.23l-.34 2.27c-.17 1.09.44 2.14 1.47 2.55l3 1.18c.47.19.97.21 1.43.09.54-.14 1.04-.47 1.38-.96l1.3-1.9c.95 0 1.91-.12 2.85-.36l.67-.18 1.81 1.44c.59.47 1.35.62 2.04.44.32-.08.62-.23.9-.44l2.52-2.01c.86-.68 1.13-1.87.66-2.86l-1-2.09c.6-1.04 1.05-2.16 1.32-3.31l2.14-.84c1.02-.4 1.64-1.46 1.47-2.54l-.48-3.19c-.16-1.09-1.06-1.92-2.15-2l-2.31-.18c-.32-.58-.66-1.09-1.05-1.57-.34-.42-.73-.83-1.16-1.22l.34-2.29c.17-1.09-.44-2.14-1.47-2.55l-3-1.18c-.47-.19-.97-.21-1.43-.09-.54.14-1.04.47-1.38.96l-1.3 1.9c-.96 0-1.91.12-2.86.36-.22.05-.43.11-.65.18l-1.81-1.44c-.6-.46-1.36-.61-2.05-.43zm4.26 26.48l-3.52-1.38.55-3.67c-.74-.55-1.3-1.09-1.77-1.66-.5-.61-.92-1.32-1.32-2.22l-3.71-.29-.56-3.74 3.42-1.34c.21-1.66.84-3.25 1.85-4.64l-1.6-3.35 2.97-2.34 2.91 2.32c.5-.21 1.01-.38 1.51-.5 1.12-.28 2.27-.36 3.4-.25l2.09-3.05 3.52 1.38-.55 3.69c.73.54 1.29 1.07 1.76 1.65.5.61.92 1.32 1.32 2.22l3.72.29.56 3.74-3.43 1.32c-.2 1.64-.84 3.23-1.84 4.63l1.6 3.35-2.96 2.35-2.92-2.32c-.48.21-1 .38-1.53.51-1.12.28-2.26.37-3.39.25l-2.08 3.05zM23.99 29.71c-3.14 0-5.7-2.56-5.7-5.7s2.56-5.7 5.7-5.7 5.7 2.56 5.7 5.7-2.56 5.7-5.7 5.7zm0-9.38c-2.03 0-3.69 1.65-3.69 3.69s1.65 3.69 3.69 3.69c2.03 0 3.69-1.65 3.69-3.69s-1.66-3.69-3.69-3.69z"/></svg>
		</Button>

		<Button tooltip="Exit studio" red={true} on:click={exitStudio}>
			<svg viewBox="0 0 48 48"><path d="M24 39c-8.27 0-15-6.73-15-15s6.73-15 15-15 15 6.73 15 15-6.73 15-15 15z"/><path class="st0" d="M31.98 17.71l-6.28 6.29 6.28 6.29-1.72 1.72-6.28-6.29-6.29 6.28-1.69-1.68 6.29-6.29-6.29-6.28 1.72-1.72 6.29 6.29 6.28-6.29 1.69 1.68z"/></svg>
		</Button>

	</div>
</div>

{#if optionsOverlay}
	<Overlay on:escape={() => { optionsOverlay = false }} modal={true} wide={true} nested={nestedOverlay} spill={true}>
		<Settings on:save={saveOptions} on:escape={() => { optionsOverlay = false }} bind:nestedOverlay/>
	</Overlay>
{/if}