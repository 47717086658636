import { get } from 'svelte/store';

import axios from 'axios';

import { bigError, csrfToken } from './stores.js';
import { sleep } from './utils.js';

let debug = false;
// let debug = true;

axios.defaults.withCredentials=true;
axios.defaults.crossDomain=true;

let baseURL = window.location.origin + '/api';

if ((window.location.hostname == 'localhost') || window.location.hostname.startsWith('192.168') || window.location.hostname.includes('sites-dev.attendzen.io')) {
	baseURL = 'https://api-dev.attendzen.io/';
	console.log('Using dev API');
	debug = true;
} else if (window.location.hostname.match(/\.attendzen\.io/)) {
	baseURL = 'https://api.attendzen.io/';
}

const prelude = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	withCredentials: true,
	// crossDomain: true
});

export const getServerData = async (endpoint, params, recursed) => {

	try {

		// console.log('X-ORIGIN', window.location.origin);

		const csrf = get(csrfToken);

		let headers = {
			'X-ORIGIN': window.location.origin,
			'X-CSRF-TOKEN': csrf
		};

		// consider removing this in production
		const authToken = sessionStorage.getItem('authToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		if (debug) {
			// console.log('GET', endpoint, authToken, csrf, params, new Date().toISOString());
		}

		const response = await prelude({
			url: endpoint,
			method: "GET",
			headers: headers,
			params: params
		});

		if (debug) console.log('data', 'GET', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		// consider removing this in production
		if (response.data.authToken) {
			sessionStorage.setItem('authToken', response.data.authToken);
		}

		return response.data;

	} catch (err) {

		bigError.set("There was an error communicating with the virtual event service.");

		return null;

	}

}

export const postServerData = async (endpoint, params, recursed) => {

	let formData = new FormData();

	if (params) {
		for (let [key, value] of Object.entries(params)) {
			formData.append(key, value);
		}
	}

	try {

		const csrf = get(csrfToken);

		let headers = {
			'X-CSRF-TOKEN': csrf
		};

		// consider removing this in production
		const authToken = sessionStorage.getItem('authToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		if (debug) console.log('POST', endpoint, authToken, csrf, new Date().toISOString());

		const response = await prelude({
			url: endpoint,
			method: "POST",
			headers: headers,
			data: formData
		});

		if (debug) console.log('data', 'POST', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		// consider removing this in production
		if (response.data.authToken) {
			sessionStorage.setItem('authToken', response.data.authToken);
		}

		return response.data;

	} catch (err) {

		bigError.set("There was an error communicating with the virtual event service.");

		return null;

	}

}