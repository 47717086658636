<script>

	import { getContext } from 'svelte';

	import Stage from "./CenterPanel/Stage.svelte";
	import Layouts from "./CenterPanel/Layouts.svelte";
	import Controls from "./CenterPanel/Controls.svelte";

	import { session } from '../lib/stores.js';

	const host = getContext('host');
	const renderKey = getContext('renderKey');

	let sw = 0;

</script>

<style>
	.outer {
		grid-area: c;
		position: relative;
		min-height: 400px;
	}
	@media (max-height: 599px) {
		.outer {
			min-height: 164vh;
		}
	}
	@media (max-width: 599px) {
		.outer {
			min-height: 90vw;
		}
	}
	.inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
</style>

{#if $session}
	<div class="outer">
		<div class="inner">
			{#key $renderKey}
				<Stage bind:sw/>
			{/key}
			{#if host}
				<Layouts {sw}/>
			{/if}
			<Controls on:exit/>
		</div>
	</div>
{/if}